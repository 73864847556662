import { useState, useContext } from 'react';
import { validateEmail } from '../commons/Utils';
import { sendFeedback } from '../commons/Services';
import { UserContext } from '../commons/UserContext';


const useLandingModalState = () => {
    const { showSnackbar } = useContext(UserContext);
    const [isModalOpen, setOpen] = useState(false);
    const [isButtonDisabled, setIsDeleteButtonDisabled] = useState(true);
    const [fields, setFields] = useState({
        name: '',
        email: '',
        message: '',
        company: ''
    });

    const setModalState = (field, value) => {
        if(field === 'open') setOpen(value);
    };

    const setField = (f, v) => {
        const newFields = { ...fields, [f]: v };
        let hasErrors = false;
        setFields(newFields);
        if(newFields?.name.length < 3) {
            hasErrors = true;
        }
        if(!validateEmail(newFields?.email)) {
            hasErrors = true;
        }
        if(newFields?.company.length < 3) {
            hasErrors = true;
        }
        if(newFields?.message.length < 1) {
            hasErrors = true;
        }
        setIsDeleteButtonDisabled(hasErrors);
    };

    const click = async () => {
        setIsDeleteButtonDisabled(true);
        const text = `Nombre : ${fields.name}, Email: ${fields.email}, Mensaje: ${fields.message}, Empresa: ${fields.company}`;
        const type = 'help';
        await sendFeedback('Landing Page Contactarse', text, null, type);
        showSnackbar("Mensaje enviado con éxito, pronto nos contactaremos");
        setFields({
            name: '',
            email: '',
            message: '',
            company: ''
        });
        setOpen(false);
    };

    return [
        { isModalOpen, fields, isButtonDisabled },
        { setModalState, setField, click }
    ];
};

export default useLandingModalState;
