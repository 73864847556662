import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { registerUser } from '../../commons/Services';
import { useHistory } from "react-router-dom";
import { setCookie, getCookie } from '../../commons/CookiesHelper';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles({
    app: {
        overflow: 'hidden',
        height: '100vh'
    },
    formContainer: {
        width: 'calc(52% - 2px)',
        float: 'right',
        textAlign: 'left',
        paddingTop: 20,
        paddingBottom: 20,
        overflowY: 'auto',
        height: '100vh',
        '@media (max-width: 900px)': {
            width: 'calc(100vw - 2px)'
        }
    },
    leftDiv: {
        width: '48%',
        height: '100%',
        float: 'left',
        background: 'rgba(250,252,252,1)',
        position: "relative",
        overflow: 'hidden',
        background: 'linear-gradient(-45deg, rgba(70, 34, 255, 0.1), rgba(0, 93, 255, 0.1))',
        '@media (max-width: 900px)': {
            display: 'none'
        }
    },
    logo: {
        display: 'block',
        margin: 'auto',
        textAlign: 'center',
        fontSize: 40,
        fontWeight: 700,
        letterSpacing: -1,
        color: '#1a2641',
        marginTop: '16vh'
    },
    form: {
        width: '90%',
        maxWidth: 450,
        margin: 'auto',
        background: 'white',
        paddingTop: 20
    },
    input: {
        width: '85%',
        height: '16px !important',
        marginLeft: '5% !important',
        marginRight: '5% !important',
        marginBottom: '28px !important',
        marginTop: '0px !important',
        borderColor: 'gray !important',
        fontFamily: 'Montserrat !important',
        padding: '12px 14px',
        paddingTop: '10px',
        border: '2px solid transparent !important',
        borderRadius: 4,
        outline: 'none !important',
        background: 'rgba(0,0,0,0.04)',
        borderColor: 'rgba(0,0,0,0.0) !important',
        transition: '.2s',
        '&:focus': {
            border: '2px solid #5043dd !important',
            height: '16px !important',
            background: 'white'
        }
    },
    headline: {
        color: '#1a2641',
        fontWeight: 800,
        marginLeft: '0%',
        marginBottom: 60,
        marginTop: '6vh',
        fontSize: 26,
        textAlign: 'center'
    },
    label: {
        marginLeft: '5%',
        fontSize: 13,
        fontWeight: 600,
        display: 'block',
        marginBottom: 5,
        color: 'rgba(0,0,0,0.7)'
    },
    button: {
        background: '#635cff',
        width: 'calc(90% + 10px)',
        marginLeft: '5% !important',
        padding: 10,
        marginTop: 10,
        marginBottom: 60,
        fontWeight: 600,
        boxShadow: 'none',
        borderRadius: 50,
        textTransform: 'capitalize',
        transition: '.3s',
        '&:hover': {
            background: '#1a2641',
        }
    },
    img: {
        // width: '150%',
        height: '80%',
        maxHeight: '90%',
        objectFit: 'cover',
        position: 'absolute',
        bottom: '10%',
        right: '10%',
        zIndex: 0
    },
    textLeft: {
        fontSize: 15,
        width: '100%',
        textAlign: 'center',
        color: 'rgba(0,0,0,0.8)',
        marginTop: 30,
        zIndex: 40
    }
});


const isEmailValid = email => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}


const hideErrorMessage = () => {
    const errorElement =
        document.getElementById("signUpErrorMessage");
    errorElement.textContent = "";
}


const showErrorMessage = (string, handleInputChange) => {
    const errorElement = document.getElementById("signUpErrorMessage");
    let errorMsg = "";
    handleInputChange("", "email");
    handleInputChange("", "password");

    if(string === "email") {
        errorMsg = "Ingrese un email válido";
    }
    else if(string === "fname" || string === "lname") {
        errorMsg = "Ingrese un nombre válido";
    }
    else {
        errorMsg = "El password debe tener al menos 6 caracteres";
    }
    errorElement.textContent = errorMsg;
}


const handleResponse = (res, history) => {
    const errorElement = document.getElementById("signUpErrorMessage");
    let errorMsg = "";
    if(res.status && res.status === 201) {
        startOnboarding(res.data, history);
        return;
    }
    if(res.status === 409) {
        errorMsg = "El email ya se encuentra registrado";
    } else {
        errorMsg = "Ocurrió un error inesperado"
    }
    errorElement.textContent = errorMsg;
}


const startOnboarding = async (data, history) => {
    setCookie('accessToken', data.accessToken, 8);
    history.push('/onboarding');
}


const SignUp = () => {
    const [values, setValues] = useState({
        email: "",
        password: "",
        fname: "",
        lname: ""
    })
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const classes = useStyles();
    const { email, password, fname, lname } = values;

    useEffect(() => {
        const cookie = getCookie('accessToken');
        if(cookie !== "") {
            history.push('/dashboard');
        }
    }, []);

    const handleInputChange = (value, key) => {
        hideErrorMessage();
        setValues({
            ...values,
            [key]: value
        });
    }

    const checkUserInput = async () => {
        if(!isEmailValid(email)) {
            showErrorMessage("email", handleInputChange);
            return;
        }
        if(password.length < 6) {
            showErrorMessage("password", handleInputChange);
            return;
        }
        if(fname.length < 2) {
            showErrorMessage("fname", handleInputChange);
            return;
        }
        if(lname.length < 2) {
            showErrorMessage("lname", handleInputChange);
            return;
        }
        setCookie('userEmail', email, 8);
        setLoading(true);
        const response = await registerUser(values);
        handleResponse(response, history);
        setLoading(false);
            
    }

    return (
        <div className={classes.app}>
            <div className={classes.leftDiv}>
                {/* <h1 className={classes.logo}>expendr</h1> */}
                {/* <h3 className={classes.textLeft}>Empezá a manejar tus gastos eficientemente</h3> */}
                <img className={classes.img} src="https://i.ibb.co/Ctr5PdP/Group-40-2.png" />
            </div>
            <div className={classes.formContainer}>
                <div className={classes.form}>
                    <h3 className={classes.headline}>Crea tu cuenta</h3>
                    <label className={classes.label}>Email</label>
                    <input
                        className={classes.input}
                        value={email}
                        onChange={e => handleInputChange(e.target.value, "email")}
                        variant="outlined"
                    />
                    <label className={classes.label}>Nombre</label>
                    <input
                        className={classes.input}
                        value={fname}
                        onChange={e => handleInputChange(e.target.value, "fname")}
                        variant="outlined"
                    />
                    <label className={classes.label}>Apellido</label>
                    <input
                        className={classes.input}
                        value={lname}
                        onChange={e => handleInputChange(e.target.value, "lname")}
                        variant="outlined"
                    />
                    <label className={classes.label}>Contraseña</label>
                    <input
                        className={classes.input}
                        value={password}
                        onChange={e => handleInputChange(e.target.value, "password")}
                        variant="outlined"
                        type="password"
                    />
                    <p id="signUpErrorMessage"></p>
                    {
                        loading ?
                            <Button
                                className={classes.button}
                                variant="contained"
                                color="primary"
                                style={{ cursor: 'default', pointerEvents: 'none', opacity: 0.4 }}
                            >
                                Cargando...
                            </Button>
                            :
                            <Button
                                className={classes.button}
                                variant="contained"
                                color="primary"
                                onClick={checkUserInput}
                                id="signup-button"
                            >
                                Crear cuenta
                            </Button>
                    }
                </div>
            </div>
        </div>
    );
}

export default SignUp;