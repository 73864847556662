import React, { useEffect, useState, useRef } from 'react';
import '../../Landing.scss';

// Components
import Board from '../board/Board';
import { Link } from 'react-router-dom';
import ExpendrIcon from '../_shared/ExpendrIcon';
import Modal from '../generics/Modal';
import useLandingModalState from '../../customHooks/useLandingModalState';
import Button from '../generics/Button';

const buttonHover = (e, isPromotionSection = false) => {
  const element = document.getElementById('buttonSpan');
  const element2 = document.getElementById('buttonSpan2');
  const promotionSection = document.getElementById('promotionSection');
  const heroButton = document.getElementById('heroButton');
  const parent = isPromotionSection ? heroButton : promotionSection;
  let top = e.clientY - parent.offsetTop - 30 + window.scrollY;
  let left = e.clientX - parent.offsetLeft - 30;
  if(!isPromotionSection) {
    element.style.top = clamp(top, 100) + 'px';
    element.style.left = clamp(left, 130) + 'px';
    return;
  }
  top = e.clientY - parent.offsetTop + 226;
  left = e.clientX - parent.offsetLeft + 386;
  console.log("Hola", { top, left });
  element2.style.top = clamp(top, 1100) + 'px';
  element2.style.left = clamp(left, 830) + 'px';
};

const clamp = (n, max) => {
  return Math.min(Math.max(n, max * -1), max);
};

const cameraEffect = (e) => {
  const element = document.getElementById('phoneController');
  const phone = document.getElementById('phone');
  const screen = document.getElementById('phoneScreen');
  const top = element.offsetTop;
  const left = element.offsetLeft;
  const height = element.offsetHeight / 2;
  const width = element.offsetWidth / 2;
  const x = clamp((e.clientX - width - left) / 4 - 35, 20);
  const y = clamp((e.clientY - height - top) / 4 - 25, 20);

  phone.style.transform =
    'perspective(1500px) rotateX(' + y * -1 + 'deg) rotateY(' + x + 'deg)';
  phone.style.transition = '';
  if(x === 0 || y === 0) return;
  screen.style.backgroundPositionX = x * 1.6 - 125 + 'px';
  screen.style.backgroundPositionY = y * -1.6 - 120 + 'px';
};

const mouseLeaveCamera = () => {
  const screen = document.getElementById('phoneScreen');
  const element = document.getElementById('phone');
  element.style.transform = 'scale(1)';
  screen.style.backgroundPositionX = '-125px';
  screen.style.backgroundPositionY = '-120px';
};


//
// Component starts /////////
//

const Gastos = () => {
  const [isTop, setIsTop] = useState(true);
  const [isDefaultPlan, setIsDefaultPlan] = useState(true);
  const [isPlansDropdownOpen, setIsPlansDropdownOpen] = useState(false);
  const [{ isModalOpen, fields, isButtonDisabled }, { setModalState, setField, click }] = useLandingModalState();
  const ref = useRef(null);

  useEffect(() => {
    document.addEventListener('scroll', changeStyle);
    return () => {
      document.removeEventListener('scroll', changeStyle);
    };
  }, [isTop]);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const changeStyle = () => {
    if(isTop !== window.scrollY < 100) {
      setIsTop(window.scrollY < 100);
    }
  };

  const handleClickOutside = (event) => {
    if(ref.current && !ref.current.contains(event.target)) {
      setIsPlansDropdownOpen(false);
    }
  };

  const handleSelectPlan = (planId) => {
    setIsPlansDropdownOpen(false);
    if(planId === 2) {
      setIsDefaultPlan(true);
    } else if(planId === 3) {
      setIsDefaultPlan(false);
    }
  };

  return (
    <div className="landingPage">
      <Modal
        title={"Contáctanos"}
        isOpen={isModalOpen}
        handleClose={() => { setModalState('open', false); }}
        mainAction={() => { }}
      >
        <div className='modalForm'>
          <div>
            <p className="dialogLabel">Nombre</p>
            <input
              className="dialogInput"
              onChange={(e) => { setField('name', e.target.value); }}
              value={fields?.name}
            />
          </div>
          <div>
            <p className="dialogLabel">Empresa</p>
            <input
              className="dialogInput"
              onChange={(e) => { setField('company', e.target.value); }}
              value={fields?.company}
            />
          </div>
          <div>
            <p className="dialogLabel">Email</p>
            <input
              className="dialogInput"
              onChange={(e) => { setField('email', e.target.value); }}
              value={fields?.email}
            />
          </div>
          <div>
            <p className="dialogLabel">Mensaje</p>
            <textarea
              className="dialogTextArea"
              onChange={(e) => { setField('message', e.target.value); }}
              value={fields?.message}
            />
          </div>
          <div className="dialogButton">
            <Button disabled={isButtonDisabled} onClick={click}>
              Enviar
            </Button>
          </div>
        </div>
      </Modal>
      <div className={isTop ? ' ' : 'guideLines'}>
        <div></div>
        <div></div>
        <div></div>
      </div>

      <section id="heroSection" className="landingContainer">
        <div className={'landingHeader'}>
          <div className="headerDiv">
            <p className="landingLogo">expendr</p>
            <Link to="/signUp">
              <button id="landing-signup-button" className="registrarseButton">Registrarse</button>
            </Link>
            <Link to="/login">
              <button id="landing-login-button" className="ingresarButton">Ingresar</button>
            </Link>
          </div>
        </div>
        <div className="halfColumn">
          <h1 className="heroTitle">
            La forma mas simple de gestionar los gastos de tu empresa
          </h1>
          <p className="heroText">
            Ahorra tiempo y dinero automatizando la forma en la que tu equipo
            registra sus gastos y tus administradores los aprueban.
          </p>
          <a
            className="heroButtonContainer"
            href="/signup"
            style={{ textDecoration: 'none' }}
          >
            <button
              className="heroButton"
              id="heroButton"
              onMouseMove={buttonHover}
            >
              Probala gratis
              <span id="buttonSpan"></span>
            </button>
          </a>
        </div>
      </section>

      <section className="qrSection">
        <div id="figure" className="boardDemo">
          <img
            className="heroFigure"
            src="https://i.ibb.co/WsdZ3Rg/Screen-Shot-2021-05-16-at-21-18-10-1.webp"
          />
        </div>
      </section>

      <section className="detailsSection">
        <div id="phoneContainer" className="landingContainer">
          <div
            id="phoneController"
            onMouseMove={(e) => cameraEffect(e)}
            onMouseLeave={mouseLeaveCamera}
          >
            <div id="phone" className="phone">
              <div className="phoneScreen" id="phoneScreen">
                {/* <div className="screenTop"></div> */}
                <div className="cameraButton"></div>
                {/* <div className="screenBottom"></div> */}
              </div>
            </div>
          </div>

          <div className="detailsSectionRightContainer">
            <h1 className="subTitle">
              Sube los{' '}
              <u>
                gastos<span></span>
              </u>{' '}
              desde donde estés
            </h1>
            <p className="subHeadline">
              Con nuestra aplicación móvil, carga las compras en el momento, no
              importa si estás fuera de la oficina.
            </p>
          </div>
        </div>
      </section>

      <section className="detailsSection">
        <div id="refundsContainer" className="landingContainer">
          <div className="detailsSectionLeftContainer">
            <h1 className="subTitle">
              Gestiona los{' '}
              <u>
                reintegros<span></span>
              </u>{' '}
              de tu equipo
            </h1>
            <p className="subHeadline">
              Lleva registro de cuanto tienes que pagarle exactamente a tus
              empleados.
            </p>
          </div>
          <div className="detailsIllustration">
            <img
              className="refundsImage"
              src="https://i.ibb.co/sv06vS5/Group-36-2.png"
            ></img>
          </div>
        </div>
      </section>

      <section className="promotionSection" id="promotionSection">
        {/* <span id="buttonSpan2"></span> */}
        <div className="bigHeadline">Empieza gratis, crece después</div>

        <div className='freePlanHeader'>
          <span className='planName'>Plan gratuito</span>
          <span className='planPrice'>$0<b>/ mes</b></span>
          <div className='freePlanDiv'>
            <span className='planItem'>- 5 usuarios</span>
            <span className='planItem'>- Gastos ilimitados</span>
            <span className='planItem'>- Hasta 10GB</span>
            <span className='planItem'>- Sin soporte</span>
            <Link to="/signUp" className="pricingButton">
              Probar gratis
              <ExpendrIcon icon="arrowRight" />
            </Link>
          </div>
        </div>
        <div className='standardPlanHeader'>
          <span className='standardPlanName'>Standard</span>
          <span className='standardPlanPrice'>$7.000<b>/ mes</b></span>
          <div className='standardPlanDiv'>
            <span className='planItem'>- 20 usuarios</span>
            <span className='planItem'>- Gastos ilimitados</span>
            <span className='planItem'>- 1TB de almacenamiento</span>
            <span className='planItem'>- Sin soporte</span>
            <Link to="/signUp" className="pricingButton">
              Probar gratis
              <ExpendrIcon icon="arrowRight" />
            </Link>
          </div>
        </div>
        <div className='enterprisePlanHeader'>
          <span className='planName'>Enterprise</span>
          <span className='planPrice'>{isDefaultPlan ? '$15.750' : '$28.000'}<b>/ mes</b></span>
          <div className='freePlanDiv'>
            <span className='selectorPlanItem' onClick={() => setIsPlansDropdownOpen(true)}>
              {isDefaultPlan ? '50 usuarios' : '100 usuarios'}
              <span className='chevron bottom'></span>
            </span>
            {
              isPlansDropdownOpen &&
              <div className='planOptions' ref={ref}>
                <div className='planOption' onClick={() => { handleSelectPlan(2); }}>50 miembros</div>
                <div className='planOption' onClick={() => { handleSelectPlan(3); }}>100 miembros</div>
              </div>
            }
            <span className='planItem'>- Gastos ilimitados</span>
            <span className='planItem'>- 10TB de almacenamiento</span>
            <span className='planItem'>- Soporte por email y slack</span>
            <Link to="/signUp" className="pricingButton">
              Probar gratis
              <ExpendrIcon icon="arrowRight" />
            </Link>
          </div>
        </div>
      </section>
      <p className="contactText">
        Ninguna de estas opciones se ajusta a tus necesidades?
        <span onClick={() => { setModalState('open', true); }}> Contáctanos</span>
      </p>
      <footer>
        <div className="footerContainer">
          <div className="footerColumn">
            <img className="footerLogo" src="/favicon.png"></img>
            <p>@ 2021 Expendr</p>
          </div>
          <div className="footerColumn centerColumn">
            <a href="https://pacific-engine-ddc.notion.site/Expendr-guias-04ccfc2adaf24dc295a1dee35736a8c5">Guías de uso</a>
          </div>
          <div className="footerRightColumn">
            <a
              className='imageLink'
              href="https://apps.apple.com/la/app/expendr/id1564492451"
              target="_blank"
            >
              <img src="https://i.ibb.co/TLHHkbd/337-3379411-appstore-badge-app-store-logo-white.png"></img>
            </a>
            <a
              className='imageLink'
              target="_blank"
              href="https://play.google.com/store/apps/details?id=com.expendr.expendr"
            >
              <img
                className="playStore"
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQF-VQTqq7V31iWq3wE55HETTy35ALxRL5Krw&usqp=CAU"
              ></img>
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Gastos;
